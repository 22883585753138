import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// import { FaAngleDown } from "react-icons/fa";

import './Header.scss'



const Navbar = () => {
    const location = useLocation();
    const handleNavClick = (section) => {
        if (location.pathname !== '/') {
            window.location.href = `/#${section}`;
        } else {
            document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <nav className="mainmenu-nav">
            <div className="mainmenu">    
                <ul className='main_menu_nav'>
                   
                    <li>
                        <Link
                            to="/#products"
                            onClick={() => handleNavClick('products')}
                        >
                            Products
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/#usecase"
                            onClick={() => handleNavClick('usecase')}
                        >
                            Use case
                        </Link>
                    </li>
                    <li>
                        <Link 
                    to="/pricing"
                        
                        >
                            Pricing
                        </Link>
                    </li>
                    <li>
                        <a 
                   
                            href="https://docs.getflick.co/reference" target="_blank" rel="noopener noreferrer" 
                        >
                            Developers
                        </a>
                    </li>
                   
                    
                    

                </ul>  
                
                <div className='menu-item-btns'>       
                        <a href="https://merchant.getflick.co" className='nav-signIn'>Sign In</a>     
                        <a href="https://merchant.getflick.co/signup"   className='btn-landing btn-landing-nav'>Get started</a>
                </div>
                
            </div>
            
        </nav>
        
    )
}

export default Navbar;